import { useCallback, useEffect, useRef, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingComponent from './LoadingComponent';
import { mainnet, useAccount, useConnect, useSignMessage, useNetwork, usePrepareContractWrite, useContractWrite, erc20ABI, useWaitForTransaction, useDisconnect } from 'wagmi';
import ReactAPIHandler from './ReactAPIHandler';
import styles from './UnityComponent.module.css';
import { parseUnits } from 'viem';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import GreenCheckmarkIcon from './GreenCheckmarkIcon/GreenCheckmarkIcon';

const dataToSign = 'Ethereum Sign In: Burger 2048 Game';

function UnityComponent({ appName }) {
    const connectData = useConnect();
    const account = useAccount();
    const sign = useSignMessage();
    const disconnectData = useDisconnect();

    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        addEventListener,
        removeEventListener,
        sendMessage
    } = useUnityContext({
        loaderUrl: "./unitybuild/" + appName + ".loader.js",
        dataUrl: "./unitybuild/" + appName + ".data.gz",
        frameworkUrl: "./unitybuild/" + appName + ".framework.js.gz",
        codeUrl: "./unitybuild/" + appName + ".wasm.gz",
        // loaderUrl: "./unitybuild/" + appName + ".loader.js",
        // dataUrl: "./unitybuild/" + appName + ".data",
        // frameworkUrl: "./unitybuild/" + appName + ".framework.js",
        // codeUrl: "./unitybuild/" + appName + ".wasm",
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        },
    });

const handleDisconnectWallet = useCallback(async () => {
    try {
        var disconect = await disconnectData.disconnectAsync();
        sendMessage("ReactBridgeController", "Disconnect");
    } catch (error){
        console.error(error.message);
    }
})

    const handleConnectWallet = useCallback(async () => {
        try {
            let token = sessionStorage.getItem('qbrr-locl-act');
            // console.log('session-token: ', token);
            if (!token) {
                let address = null;
                if (account.isConnected) {
                    address = account.address;
                } else {
                    var connected = await connectData.connectAsync({
                        // chainId: mainnet.id, 
                        connector: account.connector ?? connectData.connectors[connectData.connectors.length - 1]
                    });
                    address = connected.account;
                }
                var signature = await sign.signMessageAsync({ message: dataToSign });
                token = dataToSign + '_' + address + '_' + signature;
                sessionStorage.setItem('qbrr-locl-act', token);
                // console.log('new-token: ', token);
            }
            sendMessage("ReactBridgeController", "SetToken", token);

        } catch (error) {
            // if (error.message.includes("Connector not found")) {
            //     window.open("https://metamask.io/", "_blank");
            // } else {
            console.error(error.message);
            // }
        }
    }, [account, sign, connectData, sendMessage]);

    const [tokenContractAddress, setTokenContractAddress] = useState("");
    const [recipientAddress, setRecipientAddress] = useState("");
    const [tokenAmount, setTokenAmount] = useState("0");
    const [isClickedTransfer, setClickedTransfer] = useState(false);

    const { config, error } = usePrepareContractWrite({
        address: tokenContractAddress,
        abi: erc20ABI,
        // chainId: 1,
        functionName: 'transfer',
        args: [recipientAddress, tokenAmount ? parseUnits(tokenAmount, 18) : undefined]
    })
    const { data, write } = useContractWrite(config)

    const { isLoading } = useWaitForTransaction({
        hash: data?.hash,
        onSettled(data, error) {
            console.log('Settled', { data, error })
            statusUpdated(data?.status);
            sendMessage("ReactBridgeController", "OnTicketsBought", "");
        }
    })

    useEffect(() => {
        if (error !== null && error.message !== null && error.message?.includes("transfer amount exceeds balance")) {
            alert(
                "There is not enough $FLRBRG in this account. Please read how to top up at https://flrbrg.io/ or click 'Rules'"
            );
        }
        console.error("config error: " + error);
    }, [error])

    useEffect(() => {
        if (isLoading || !write || !recipientAddress || !tokenAmount || !isClickedTransfer) {
            return;
        }
        setClickedTransfer(false);

        write?.();

    }, [isLoading, isClickedTransfer, write, tokenContractAddress, recipientAddress, tokenAmount]);

    const sendTokens = useCallback((
        userLogginedAddress,
        tokenContractAddress,
        recipientAddress,
        tokenAmount
    ) => {
        setTokenContractAddress(tokenContractAddress);
        setRecipientAddress(recipientAddress);
        setTokenAmount(tokenAmount);

        if (!account.isConnected || (account.address.toLowerCase() !== userLogginedAddress.toLowerCase())) {
            alert(
                'Please log in or switch account to wallet with the same address as in the game. \n' +
                "Address should be: " + userLogginedAddress
            );
            return;
        }

        setClickedTransfer(true);
    }, [account.address, account.isConnected]);

    useEffect(() => {
        addEventListener("BuyTicketsForTokens", sendTokens);
        addEventListener("ConnectWallet", handleConnectWallet);
        addEventListener("DisconnectWallet", handleDisconnectWallet);
        return () => {
            removeEventListener("BuyTicketsForTokens", sendTokens);
            removeEventListener("ConnectWallet", handleConnectWallet);
            removeEventListener("DisconnectWallet", handleDisconnectWallet);
        };
    }, [addEventListener, removeEventListener, handleConnectWallet, handleDisconnectWallet, sendTokens]);


// Button Pending
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const successText = 'Success';
    const pendingText = 'Pending';
    const errorText = 'Error';
    
    useEffect(() => {
        if (isLoading) {
            setIsButtonVisible(true);
            setButtonText(pendingText);
        }
    }, [isLoading]);
    
    async function statusUpdated (status)  {
        console.log("data?.status: " + status);
        if (status === 'success') {
            setButtonText(successText);
            setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
        } else if(status === 'error') {
            setButtonText(errorText);
            setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
        } else {
            setIsButtonVisible(false);
        }
    }
    


    return (
        <div className={styles.unityWrapperBG}>
            <>{window.ethereum?.isMetaMask}</>
            <LoadingComponent
                isLoaded={isLoaded}
                loadingProgression={loadingProgression}
            />
            <Unity
                unityProvider={unityProvider}
                devicePixelRatio={2}
                style={{
                    display: isLoaded ? "block" : "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                }}
            />
            <ReactAPIHandler
                addEventListener={addEventListener}
                removeEventListener={removeEventListener}
            />
            {isButtonVisible && (
                <button
                    disabled={true}
                    className={styles.pendingBtn} // Примените стили к кнопке
                >
                    {buttonText} 
                    {buttonText === pendingText && <LoadingSpinner /> } {/* Покажите крутящийся круг загрузки */}
                    {buttonText === successText && <GreenCheckmarkIcon />} {/* Покажите галочку */}
                </button>
            )}
        </div>
    );
}

export default UnityComponent;